export interface IRecommendations {
    additionalSections: Array<IRecommendationAdditionalSection>;
    articles: Array<IRecommendationArticle>;
    targetArticle: ITargetArticle;
    assetBaseAddress: string;
    expanderLabel: string;
    itemOrigin: string;
}

export interface ITargetArticle {
    name: string;
    masterArticleNo: number;
    colorCode: number;
    image: string;
}

export interface IRecommendationAdditionalSection {
    additionalSectionType: number;
    articles: Array<IRecommendationArticle>;
    sectionLabel: string;
}

export interface IRecommendationArticle {
    description: string;
    image: IImage;
    quickOrderModalLink: string;
    detailPageLink: string;
    salesArticleNo: string;
    masterArticleNo: number;
    colorCode: number;
    sizeCode: number;
}

export interface IImage {
    url: string;
    type: ImageType;
}

export enum ImageType {
    Main = 'main',
    Action = 'action',
}